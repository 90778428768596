import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Gizlilik Politikası</h1>
      <p><strong>Privacy Policy</strong></p>
      <p><strong>Tunga Math Skills</strong><br />Date: July 2023</p>

      <p><strong>1. Privacy Policy</strong></p>
      <p>
        Tunga Math Skills operates the Tunga Games website and the Tunga Math Skills app, which we’ll call the “Service” here. This Privacy Policy tells you about how we collect, use, and share your personal information when you use our Service. We don’t share your information with anyone except as described here. By using our Service, you’re saying you agree with this Privacy Policy and our Terms and Conditions.
      </p>

      <p><strong>2. Information Collection And Use</strong></p>
      <p>
        We may ask you for some personal information that can be used to contact you or identify who you are while you’re using our Service.
      </p>

      <p><strong>3. Log Data</strong></p>
      <p>
        We might also collect some information that your browser sends when you use our Service. This could include your computer or mobile device’s Internet Protocol (”IP”) address, browser type and version, the pages of our Service that you visit, and other stats. We use services like Google Analytics to collect and analyze this information to improve our Service.
      </p>

      <p><strong>4. Cookies</strong></p>
      <p>
        Cookies are small files that include an anonymous unique identifier. They’re sent to your browser from a website and stored on your computer’s hard drive. We use cookies to collect information. You can tell your browser to refuse all cookies or indicate when a cookie is being sent. But if you don’t accept cookies, some parts of our Service may not work.
      </p>

      <p><strong>5. Service Providers</strong></p>
      <p>
        We may use third-party companies to help us run our Service or to help us understand how our Service is used. They have access to your personal information only to do these tasks and must not use it for other purposes.
      </p>

      <p><strong>6. In-Game Purchases</strong></p>
      <p>
        Our game includes in-game purchases. Users can buy in-game currency to unlock different features and rewards.
      </p>

      <p><strong>7. Compliance With Laws</strong></p>
      <p>
        We may share your personal information if we have to by law or if we believe it’s needed to follow the law, respond to law enforcement requests, or to protect our Service.
      </p>

      <p><strong>8. Business Transaction</strong></p>
      <p>
        If we are part of a merger, acquisition, or asset sale, your personal information may be transferred. If this happens, we’ll let you know before your information is transferred and becomes subject to a different Privacy Policy.
      </p>

      <p><strong>9. Security</strong></p>
      <p>
        We care about the security of your personal information, but no method of transmission over the Internet or electronic storage is 100% secure. We do our best to protect your personal information, but we can’t guarantee its absolute security.
      </p>

      <p><strong>10. Links To Our Other Sites</strong></p>
      <p>
        Our Service may contain links to other sites and services that are operated by us. These could include our official website and associated platforms. As these are all under our control, we ensure that the same high standards of privacy are maintained across all our platforms. If you follow a link to any of these websites, please note that they have their own privacy policies, which should be in line with this one. Nevertheless, we recommend you familiarize yourself with these policies when you visit our other sites.
      </p>

      <p><strong>11. Children’s Privacy</strong></p>
      <p>
        We place high importance on the safety and privacy of children who use our services. In accordance with legal requirements, we do not knowingly collect personally identifiable information from children under the age of 13 without parental consent. In our app, we collect some data to personalize and improve user experience in the game. This includes data related to game progress, scores, achievements, and in-game activity. This information is used to track player progress, provide personalized features, and maintain the quality of our game service. When it comes to advertising, we use Unity Ads, which provides contextual advertising suitable for children. Any data used for this purpose is entirely anonymous and does not personally identify individual users. Unity Ads uses data about the context of the app, not personal information or behavior, to select the ads shown.
      </p>

      <p><strong>12. Advertising Practices in Kids Category App</strong></p>
      <p>
        We follow the App Store Review Guidelines for our app ads. We use Unity Ads for our advertising, which is certified as family-friendly. These are the steps we follow:
      </p>
      <ul>
        <li><strong>Selection of Advertisers</strong>: We chose Unity Ads because they’re certified as family-friendly.</li>
        <li><strong>Ad Content Review</strong>: Unity Ads reviews each ad to make sure it’s suitable for a young audience.</li>
        <li><strong>Data Use and Privacy</strong>: Unity Ads follows COPPA and GDPR rules to protect our young users’ privacy and safety. They do not collect personal data from users in the Kids Category app, and ads are based on the app, not the user.</li>
      </ul>
      <p>We will update this section if our ad practices change. If you have any questions about our ads in the Kids Category app, please email us at info@tungasoft.com.</p>

      <p><strong>13. Changes To This Privacy Policy</strong></p>
      <p>
        We might update our Privacy Policy sometimes. We’ll post the new Privacy Policy on this page. We recommend you check this page periodically for updates.
      </p>

      <p><strong>14. Contact Us</strong></p>
      <p>If you have any questions about this Privacy Policy, please email us at info@tungasoft.com.</p>
    </div>
  );
};

export default PrivacyPolicy;
