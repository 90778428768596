import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import MobileGames from './pages/MobileGames';
import PCGames from './pages/PCGames';
import Investors from './pages/Investors';
import Contact from './pages/Contact';
import Chatbot from './components/Chatbot'; // Chatbot bileşenini ekleyin
import './styles.css';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';





function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mobile-games" element={<MobileGames />} />
        <Route path="/pc-games" element={<PCGames />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/contact" element={<Contact />} />
		<Route path="/privacy-policy" element={<PrivacyPolicy />} />
<Route path="/terms-of-use" element={<TermsOfUse />} />
      </Routes>
      <Chatbot /> {/* Chatbot bileşenini buraya ekleyin */}
      <Footer />
    </Router>
  );
}

export default App;
