import React from 'react';
import './Footer.css';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';




function Footer() {
  return (
    <footer className="footer">
      <div className="footer-top">
        <ul className="footer-links">
          <li><a href="/mobile-games">Mobil Oyunlar</a></li>
           <a href="/privacy-policy">Privacy Policy</a>
    <a href="/terms-of-use">Terms of Use</a>
          <li><a href="/contact">İletişim</a></li>
        </ul>
      </div>
      <div className="footer-social">
        <a href="https://www.instagram.com/tungagamesofficial/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        <a href="https://www.linkedin.com/company/tungagames/?viewAsMember=true" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
        <a href="https://x.com/tunga_soft" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
      </div>
      <div className="footer-info">
        <p className="footer-company-name">TUNGASOFT YAZILIM EĞİTİM VE DANIŞMANLIK A.Ş.</p>
        <p className="footer-address">75. Yıl OSB Bilim Caddesi No: 5/107, Odunpazarı - Eskişehir</p>
        <a href="tel:08503057562" className="footer-phone">08503057562</a>
      </div>
      <div className="footer-bottom">
        <p>© 2024 TUNGASOFT A.Ş - Tüm Hakları Saklıdır.</p>
      </div>
    </footer>
  );
}

export default Footer;
