import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="contact-container">
            <h2 className="contact-title">BİZE ULAŞIN</h2>
            <div className="contact-details">
                <p><strong>Tungasoft Yazılım Eğitim ve Danışmanlık Anonim Şirketi</strong></p>
                <p>Ticaret sicil No: 2880 &nbsp;|&nbsp; Mersis No: 0867089265600001</p>
                <h3>Adres</h3>
                <p>75. yıl osb bilim caddesi no: 5/107 odunpazarı, eskişehir</p>
                <p>+90 850 305 75 62 &nbsp;|&nbsp; info@tungasoft.com</p>
            </div>
            

            <div className="map-container">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3067.431293965003!2d30.63254907544872!3d39.752423895804306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb9b52e941afcb%3A0xe3d27b9276ad639e!2sTunga%20Games!5e0!3m2!1str!2str!4v1730032151834!5m2!1str!2str"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Tungasoft Map Location"
                ></iframe>
            </div>
        </div>
    );
};

export default Contact;
