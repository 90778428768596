import React from 'react';
import './Yatirimcilar.css';
import { FaDownload } from 'react-icons/fa';

const reports = [
    { year: '2021', name: 'Yılı Faaliyet Raporu', fileType: '(pdf)', link: '/reports/2021-faaliyet-raporu.pdf' },
    { year: '2022', name: 'Faaliyet Raporu', fileType: '(pdf)', link: '/reports/2022-faaliyet-raporu.pdf' },
    { year: '2023', name: 'Faaliyet Raporu', fileType: '(pdf)', link: '/reports/2023-faaliyet-raporu.pdf' },
];

const bulten = [
    { title: 'Matematik Roketi Dört İşlem Oyunu', fileType: '(pdf)', link: '/bultenler/bulten1.pdf' }
];

const Yatirimcilar = () => {
    return (
        <div className="faaliyet-raporlari-container">
            {/* Faaliyet Raporları Section */}
            <div className="section">
                <h2 className="section-title">FAALİYET RAPORLARI</h2>
                <h3 className="section-subtitle">Tunga Soft A.Ş Faaliyet Raporları</h3>
                <div className="report-list">
                    {reports.map((report, index) => (
                        <div className="report-item" key={index}>
                            <span>{report.year} {report.name} {report.fileType}</span>
                            <a href={report.link} download className="download-link">
                                <FaDownload /> İndirme
                            </a>
                        </div>
                    ))}
                </div>
            </div>

            {/* Basın Bültenleri Section */}
            <div className="section">
                <h2 className="section-title">BASIN BÜLTENLERİMİZ</h2>
                <h3 className="section-subtitle">Tunga Soft A.Ş Basın Bültenleri</h3>
                <div className="report-list">
                    {bulten.map((item, index) => (
                        <div className="report-item" key={index}>
                            <span>{item.title} {item.fileType}</span>
                            <a href={item.link} download className="download-link">
                                <FaDownload /> İndirme
                            </a>
                        </div>
                    ))}
                </div>
            </div>

            {/* Communication Form */}
            <div className="communication-form-container">
                <h2 className="section-title">YATIRIMCI İLİŞKİLERİ BİRİMİ</h2>
                <h3 className="section-subtitle">Bize mesaj atın!</h3>
                <form className="communication-form">
                    <div className="form-group">
                        <input type="text" name="name" placeholder="Ad" required />
                    </div>
                    <div className="form-group">
                        <input type="email" name="email" placeholder="E-posta*" required />
                    </div>
                    <div className="form-group">
                        <textarea name="message" placeholder="Mesaj" required></textarea>
                    </div>
                    <button type="submit" className="submit-button">GÖNDER</button>
                </form>
            </div>
        </div>
    );
};

export default Yatirimcilar;
