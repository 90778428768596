import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import videoSource from '../assets/background-video.mp4';
import './Home.css';
import { FaGamepad, FaBullhorn, FaGlobe, FaLock, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import news1Image from '../assets/news1.png';
import news2Image from '../assets/news2.jpg';
import news3Image from '../assets/news3.png';


const newsData = [
  {
    id: 1,
    title: 'PC Oyun Projemiz',
    description: 'Eğitici oyunlardan AAA türü bir oyuna...',
    fullDescription: [
      { type: 'paragraph', content: 'Tunga Soft için yeni bir dönem başlıyor. Blockchain tabanlı yeni oyun projesinin geliştirme çalışmalarına başlayan firmamız, bu alandaki ilk projesi olan "Kingdom of Reburn" ile oyunculara yepyeni bir deneyim sunmayı hedefliyor.' },
      { type: 'subtitle', content: 'Kingdom of Reburn: Yeni Nesil MMO RPG' },
      { type: 'paragraph', content: 'Bu oyun, fantastik bir dünya üzerinde kendine özgü hikayesi, karakterleri ve mekanikleri ile MMO RPG türünde oyun severlere sunulacaktır.' },
      { type: 'paragraph', content: 'Oyuncular, çeşitli roller üstlenerek dijital varlıklarını oluşturabilecek, ticaret yapabilecek, savaşabilecek ve diğer oyuncularla etkileşime geçebilecek.' },
      { type: 'subtitle', content: 'Eğitici Oyunlardan Blockchain\'e' },
      { type: 'paragraph', content: 'Eğitici oyun sektöründe faaliyetlerine devam eden Tunga Games, kendisine ikinci bir ürün kategorisi açarak büyümesini hızlandırmayı hedefliyor.' },
	  {type: 'subtitle', content: 'Oyun ne zaman çıkacak'},
	  {type: 'paragraph', content: 'Oyunun ilk kapalı beta sürümü 2025 Q1 de ve açık betasının ise 2026 Q1 de piyasaya sunulması planlanmakta olup Unreal Engine oyun motoru üzerinde geliştirilecek ve PC (Windows) cihazlarda oynanabilecek. Oyunu Epic Store üzerinden oyun severler indirebilecekler.'}
	  // Add more structured content as needed
    ],
    image: news1Image // Added a comma here
  },
 {
  id: 2,
  title: '1.000.000₺ Yatırım ',
  description: 'Tungasoft,Founder One\'dan yatırım aldı.',
  fullDescription: [
    { type: 'paragraph', content: 'Eğitim ve sosyal oyunlar alanında faaliyet gösteren yerli oyun stüdyomuz Tunga Games, 1 milyon TL yatırım aldı! Bu başarılı adım, eğitim odaklı ve gelişim destekleyici oyunlar konusundaki tutkumuzun bir göstergesi.' },
    { type: 'paragraph', content: 'İlköğretim öğrencilerine yönelik geliştirdiğimiz oyunlarımız, onların sayısal ve matematiksel becerilerini, geometrik düşünme yeteneklerini ve işlemsel akıcılıklarını artırmayı amaçlıyor. Ayrıca, oyunlarımızda çocuklara geri dönüşümün önemi ve doğal yaşamın korunması gibi değerleri de eğlenceli bir şekilde öğretiyoruz.' },
    { type: 'subtitle', content: 'Yatırımcı Destekleri ve Partnerlikler' },
    { type: 'paragraph', content: 'Bu yatırımın kaynağı ise Founder One\'dan geldi. Tunga Games olarak Workup Gaming’in ilk dönemine seçilen 11 girişim arasında yer almaktan da büyük gurur duyuyoruz. Projelerimiz arasında Tunga Math Skills, Bilge Tunga ve Math Rocket gibi oyunlarımız da var.' },
    { type: 'paragraph', content: 'Bu yolculukta bize destek olan herkese sonsuz teşekkürler! Beraber daha nice başarılara imza atacağımıza inanıyoruz.' }
  ],
  image: news2Image
},

 {
  id: 3,
  title: 'Tunga Math Skills Yayında!',
  description: 'Eğitici oyun sektörüne iddialı bir giriş yapmanın gururunu yaşıyoruz.',
  fullDescription: [
    { type: 'paragraph', content: '640 günlük sürecimizin sonuna gelmiş bulunmaktayız. Eğitici oyun sektörüne iddialı bir projemizle giriş yapmanın gururunu yaşıyoruz. Tunga Games ekibi olarak bu projeye çok emek verdik. Bugün oyunumuzun her iki markette de canlı olarak yayınlandığı ilk gün. Bu bir başlangıç aslında, oyunumuzu geliştirme sürecimiz devam edecek.' },
    { type: 'subtitle', content: 'Tunga Math Skills: Matematiği Sevdiren Oyun' },
    { type: 'paragraph', content: 'Tunga Math Skills sizlerle. Anaokulu seviyesinden dördüncü sınıfa kadar olan tüm öğrencilerimize hitap eden oyunumuzun temel amacı onlara matematiği sevdirmek.' },
    { type: 'paragraph', content: 'İçinde 60\'tan fazla eğitsel oyun/oyunlaştırma olmasının yanında, 3D karakter ve yapı özelleştirme seçenekleri, evcilik oyunu, resim yapma, kitap okuma, karakter bakımı gibi oyun mekanikleri ile tüm dünya çocuklarının kullanımına ücretsiz olarak sunuyoruz.' }
  ],
  image: news3Image
}

];

function Home() {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [email, setEmail] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');

  const handleButtonClick = () => {
    navigate('/mobile-games');
  };

  const openModal = (news) => {
    setSelectedNews(news);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedNews(null);
  };

  const handleSubscribe = async () => {
    if (!email) {
      setSubscriptionStatus('Lütfen bir e-posta adresi girin.');
      return;
    }

    try {
      const response = await axios.post(
        'https://api.brevo.com/v3/contacts',
        {
          email: email,
          listIds: [25], // Replace with your actual list ID
          updateEnabled: true,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': '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', // Replace with your Brevo API key
          },
        }
      );

      if (response.status === 201) {
        setSubscriptionStatus('Abone olma işlemi başarılı! Teşekkür ederiz.');
        setEmail(''); // Clear the email field
      } else {
        setSubscriptionStatus('Bir hata oluştu, lütfen tekrar deneyin.');
      }
    } catch (error) {
      setSubscriptionStatus('Hata: Abone olma başarısız.');
    }
  };

  // Helper functions for formatting content
  const paragraph = (text) => <p className="modal-paragraph">{text}</p>;
  const subtitle = (text) => <h3 className="modal-subtitle">{text}</h3>;

  return (
    <div className="home-container">
      {/* Video Section */}
      <div className="video-container">
        <video autoPlay loop muted playsInline className="background-video">
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="cta-overlay">
          <h1 className="cta-title">Tunga Soft</h1>
          <p className="cta-subtitle">Çocuklar için eğitici ve eğlenceli oyunlar yapıyoruz!</p>
          <button className="cta-button" onClick={handleButtonClick}>Oyunlarımızı Keşfedin!</button>
        </div>
      </div>

      {/* About Section */}
      <div className="about-section">
        <h2 className="about-title">HAKKIMIZDA</h2>
        <div className="about-content">
          <h3 className="about-subtitle">Faaliyet Alanı</h3>
          <p>
            Şirketimiz dijital mobil oyun sektöründe eğitici temalı, aynı zamanda eğlendirici, yıllarca oynanabilecek ve hatırlanabilecek marka değerine sahip oyunlar üretmek üzere faaliyet göstermektedir.
          </p>
          <h3 className="about-subtitle">Misyon</h3>
          <p>
            Dijital oyun sektöründe; düşünmeyi tetikleyen, anlayarak öğrenmeyi sağlayan daha ileri öğrenmeler için temel oluşturan becerileri hedefleyen, öğrenme araçlarının yer aldığı eğitici oyunlar ve sosyal oyunlar üretmek.
          </p>
        </div>
      </div>

      {/* Services Section */}
      <div className="services-section">
        <h2 className="services-title">SERVİSLERİMİZ</h2>
        <div className="services-cards">
          <div className="service-card">
            <FaGamepad className="service-icon" />
            <h3>Oyun Geliştirme</h3>
          </div>
          <div className="service-card">
            <FaBullhorn className="service-icon" />
            <h3>Oyun Yayınlama</h3>
          </div>
          <div className="service-card">
            <FaGlobe className="service-icon" />
            <h3>Web Geliştirme</h3>
          </div>
          <div className="service-card">
            <FaLock className="service-icon" />
            <h3>Blockchain Teknolojileri</h3>
          </div>
        </div>
      </div>

      {/* News Section */}
      <div className="news-section">
        <h2 className="news-title">HABERLER</h2>
        <div className="news-cards">
          {newsData.map((news) => (
            <div className="news-card" key={news.id}>
              <img src={news.image} alt={news.title} className="news-image" />
              <h3 className="news-card-title">{news.title}</h3>
              <p className="news-card-description">{news.description}</p>
              <button className="news-card-button" onClick={() => openModal(news)}>Daha Fazla Oku</button>
            </div>
          ))}
        </div>
      </div>

      {/* Subscription Section */}
      <div className="subscribe-section">
        <h2 className="subscribe-title">Abone Ol</h2>
        <div className="subscribe-form">
          <input
            type="email"
            placeholder="E-posta"
            className="subscribe-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="subscribe-button" onClick={handleSubscribe}>KAYDOL</button>
        </div>
        {subscriptionStatus && <p>{subscriptionStatus}</p>}
      </div>

      {/* News Modal */}
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="News Detail"
        className="news-modal"
        overlayClassName="news-modal-overlay"
      >
        <button onClick={closeModal} className="close-button">
          <FaTimes />
        </button>
        {selectedNews && (
          <div className="news-modal-content">
            <h2>{selectedNews.title}</h2>
            <img src={selectedNews.image} alt={selectedNews.title} className="news-modal-image" />
            {selectedNews.fullDescription.map((item, index) => (
              item.type === 'paragraph' ? paragraph(item.content) :
              item.type === 'subtitle' ? subtitle(item.content) : null
            ))}
          </div>
        )}
      </ReactModal>
    </div>
  );
}

export default Home;
