import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationTR from './locales/tr/translation.json';

i18n.use(initReactI18next).init({
  resources: {
    tr: {
      translation: translationTR,
    },
  },
  lng: 'tr', // default language
  fallbackLng: 'tr',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
