import React, { useState, useEffect } from 'react';
import './PCGames.css';
import heroBackground from '../assets/hero-bg.jpg';
import axios from 'axios';
import { FaTelegram, FaDiscord, FaTwitter, FaGlobe } from 'react-icons/fa';

const PCGames = () => {
    // State to store download count
    const [downloadCount, setDownloadCount] = useState(0);

    // Fetch download count from the backend when the component loads
    useEffect(() => {
        axios.get('http://localhost:3001/api/downloadCount')
            .then(response => setDownloadCount(response.data.count))
            .catch(error => console.error('Failed to fetch download count:', error));
    }, []);

    // Function to increment download count and update backend
    const handleDownload = () => {
        axios.post('http://localhost:3001/api/incrementDownloadCount')
            .then(response => setDownloadCount(response.data.count))
            .catch(error => console.error('Failed to update download count:', error));
    };

    return (
        <div className="pc-games-container">
            {/* Hero Section */}
            <section
                className="hero-section"
                style={{
                    backgroundImage: `url(${heroBackground})`,
                }}
            >
                <h1>Kingdom of Reburn</h1>
                <p>Küllerinde doğan bir efsane krallık...</p>
                
                {/* Whitepaper Download Link */}
                <a 
                    href="/whitepaper.pdf" 
                    download="Whitepaper.pdf" 
                    className="whitepaper-button" 
                    onClick={handleDownload} 
                >
                    Whitepaper
                </a>

               
            </section>

            {/* Video Section */}
            <div className="video-section">
                <h2 className="video-title">3D çevre tasarımı</h2>
                <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/-kePBm4CaOo?rel=0&playlist=-kePBm4CaOo"
                    title="3D Çevre Tasarımı"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>

            {/* Community Section */}
            <section className="community-section">
                <p className="community-description">
                    Değerli oyun severler, bu proje AAA kalitesinde bir proje olduğundan geliştirme süresi uzun zaman alacaktır. Bu süreçte sizlerin desteğine çok ihtiyacımız olacak. Aşağıdaki kanallardan bizlere katılırsanız çok memnun oluruz.
                </p>
                <h2 className="community-title">Bizi takip edin!</h2>
               
<div className="icon-container">
    <a href="https://www.kingdomofreburn.com/" target="_blank" rel="noopener noreferrer">
        <FaGlobe className="community-icon" />
    </a>
    <a href="https://t.me/kingdomofreburn" target="_blank" rel="noopener noreferrer">
        <FaTelegram className="community-icon" />
    </a>
    <a href="https://x.com/kingdomofreburn" target="_blank" rel="noopener noreferrer">
        <FaTwitter className="community-icon" /> {/* Updated Twitter/X icon */}
    </a>
    <a href="https://discord.gg/KFa947pp4j" target="_blank" rel="noopener noreferrer">
        <FaDiscord className="community-icon" />
    </a>
</div>
            </section>
        </div>
    );
};



document.addEventListener("DOMContentLoaded", function() {
    const videoContainer = document.querySelector(".video-container iframe");
    if (videoContainer) {
        function resizeVideo() {
            const aspectRatio = 16 / 9;
            const containerWidth = videoContainer.parentElement.offsetWidth;
            const containerHeight = videoContainer.parentElement.offsetHeight;

            // Adjust the scale based on container size
            if (containerWidth / containerHeight < aspectRatio) {
                videoContainer.style.width = "120%";
                videoContainer.style.height = "100%";
                videoContainer.style.transform = "translateX(-50%)";
            } else {
                videoContainer.style.width = "100%";
                videoContainer.style.height = "120%";
                videoContainer.style.transform = "translateX(-50%)";
            }
        }

        // Initial resize
        resizeVideo();

        // Adjust on window resize
        window.addEventListener("resize", resizeVideo);
    }
});


export default PCGames;


