import React from 'react';
import './MobileGames.css';
import { FaStar, FaChalkboardTeacher } from 'react-icons/fa';
import icon1Image from '../assets/icon1.jpg';
import icon2Image from '../assets/icon2.jpg';
import icon3Image from '../assets/icon3.jpg';
import googlePlayIcon from '../assets/google-play.png';
import appleStoreIcon from '../assets/apple-store.png';

const gamesData = [
  {
    id: 1,
    title: 'Tunga Matematik Yetenekleri',
    subtitle: 'Tunga Games',
    description: 'Eğlenceli matematik için eğitici oyunlar',
    rating: 4.8,
    reviews: 25,
    downloads: '1 B+',
    teacherApproved: true,
    ageRating: 3,
    googlePlayLink: 'https://play.google.com/store/apps/details?id=com.TungaSoft.Tunga',
    appleStoreLink: 'https://apps.apple.com/app/id1620859326',
    videoUrl: 'https://www.youtube.com/embed/3GYtc3vmHD0?si=SY0JScaxiCc2KhQh',
    icon: icon1Image
  },
  {
    id: 2,
    title: 'Bilge Tunga',
    subtitle: 'Tunga Games',
    description: 'İşler belleği geliştiren eğitici platform oyunu',
    rating: 4.6,
    reviews: 189,
    downloads: '5B+',
    teacherApproved: true,
    ageRating: 3,
    googlePlayLink: 'https://play.google.com/store/apps/details?id=com.TungaSoftProje.TungaSoft',
    appleStoreLink: 'https://apps.apple.com/app/id1521382680',
    videoUrl: 'https://www.youtube.com/embed/rOeR8SfpM5w?si',
    icon: icon3Image
  },
  {
    id: 3,
    title: 'Math Rocket',
    subtitle: 'Tunga Games',
    description: 'Dört işlemin eğlenceli bir uygulaması',
    rating: 5.0,
    reviews: 20,
    downloads: '50+',
    teacherApproved: true,
    ageRating: 3,
    googlePlayLink: 'https://play.google.com/store/apps/details?id=com.TungaSoft.MathRocket',
    appleStoreLink: 'https://apps.apple.com/app/id6450061833',
    videoUrl: 'https://www.youtube.com/embed/VKVwNcqW9Mk?si',
    icon: icon2Image
  }
];

function MobileGames() {
  return (
    <div className="mobile-games-container">
      {gamesData.map(game => (
        <div key={game.id} className="game-card">
          <div className="game-info-section">
            <h2 className="game-title">{game.title}</h2>
            <p className="game-subtitle">{game.subtitle}</p>
            <img src={game.icon} alt={`${game.title} icon`} className="game-icon" />
            <p className="game-description">{game.description}</p>
            <div className="game-details">
              <div className="rating">
                <FaStar className="star-icon" /> {game.rating} <span>({game.reviews} yorum)</span>
              </div>
              <div className="downloads">• {game.downloads} indirme</div>
              {game.teacherApproved && (
                <div className="teacher-approved">
                  • <FaChalkboardTeacher className="teacher-icon" /> Öğretmen Onaylı
                </div>
              )}
              <div className="age-rating">• PEGI {game.ageRating}</div>
            </div>
            <div className="download-buttons">
              <a href={game.googlePlayLink} target="_blank" rel="noopener noreferrer" className="download-button">
                <img src={googlePlayIcon} alt="Google Play" className="store-icon" />
              </a>
              <a href={game.appleStoreLink} target="_blank" rel="noopener noreferrer" className="download-button">
                <img src={appleStoreIcon} alt="Apple Store" className="store-icon" />
              </a>
            </div>
          </div>
          <div className="game-video-section">
            <iframe
              width="100%"
              height="280"
              src={game.videoUrl}
              title={game.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MobileGames;
