import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo50 from './assets/50x50.PNG';
import logo76 from './assets/76x76.PNG';
import logo120 from './assets/120x120.PNG';
import logo125 from './assets/125x125.PNG';

function Header() {
  const { t } = useTranslation();

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <div className="container"> {/* Add container here */}
        <Navbar.Brand as={Link} to="/">
          <img src={logo50} alt="Tunga Soft Logo Small" className="logo-small logo-animated" />
          <img src={logo76} alt="Tunga Soft Logo Medium" className="logo-medium logo-animated" />
          <img src={logo120} alt="Tunga Soft Logo Large" className="logo-large logo-animated" />
          <img src={logo125} alt="Tunga Soft Logo XLarge" className="logo-xlarge logo-animated" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/">{t('header.home')}</Nav.Link>
            <Nav.Link as={Link} to="/mobile-games">{t('header.mobileGames')}</Nav.Link>
            <Nav.Link as={Link} to="/pc-games">{t('header.pcGames')}</Nav.Link>
            <Nav.Link as={Link} to="/investors">{t('header.investors')}</Nav.Link>
            <Nav.Link as={Link} to="/contact">{t('header.contact')}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default Header;
