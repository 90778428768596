import React, { useState } from 'react';
import './Chatbot.css';
import faqData from './faq.json'; // Importing JSON data directly

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    const findAnswer = (userInput) => {
        const lowercasedInput = userInput.toLowerCase();
        const foundFaq = faqData.find(faq => 
            faq.keywords.some(keyword => lowercasedInput.includes(keyword.toLowerCase())) || 
            faq.question.toLowerCase().includes(lowercasedInput)
        );
        return foundFaq ? foundFaq.answer : "Bu sorunuza buradan cevap veremiyoruz. Dilerseniz info@tungasoft.com üzerinden sorunuzu mail ile sorabilirsiniz.";
    };

    const sendMessage = () => {
        const userInput = document.getElementById("userInput").value;
        const faqContent = document.getElementById("faqContent");

        if (userInput.trim() !== "") {
            const userMessage = document.createElement("div");
            userMessage.textContent = userInput;
            userMessage.className = "user-message";
            faqContent.appendChild(userMessage);

            const responseMessage = findAnswer(userInput);

            const response = document.createElement("div");
            response.textContent = responseMessage;
            response.className = "bot-message";
            faqContent.appendChild(response);

            document.getElementById("userInput").value = ""; // Clear input field
            faqContent.scrollTop = faqContent.scrollHeight;  // Scroll chat window
        }
    };

    return (
        <div className="chatbot-container">
            <div className="chatbot-icon" onClick={toggleChat}>
                💬
            </div>
            {isOpen && (
                <div className="chatbot-window">
                    <div className="chatbot-header">
                        <h4>Chatbot</h4>
                        <button onClick={toggleChat}>X</button>
                    </div>
                    <div className="chatbot-body" id="faqContent">
                        {/* FAQ content will be appended here */}
                    </div>
                    <div className="chatbot-footer">
                        <input type="text" placeholder="Sorunuzu yazın..." id="userInput" />
                        <button onClick={sendMessage}>Gönder</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chatbot;
